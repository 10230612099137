<template>
  <div>
    <v-sheet elevation="12">
      <v-banner>
        <v-alert type="error">{{ errorMessage }}</v-alert>
        <p>{{ errorDescription }}</p>
        <template v-slot:actions v-if="originalLocation">
          <v-btn  :to="originalLocation">
            <v-icon left>arrow_left</v-icon>
            Go Back
          </v-btn>
        </template>
      </v-banner>
    </v-sheet>
  </div>
</template>

<script>
const errors = {
  route: {
        errorMessage:
          "Sorry, you don't have the right access to reach the page requested",
        errorDescription: `If you need to access the page you requested,
          please contact the site administrator and inform them
          so that they can assign you the proper permissions.`
  }
}

export default {
  name: "RouteError",
  data() {
    return {
      errorMessage: null,
      errorDescription: null,
      originalLocation: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    console.log(`to.query=${to.query}`);
    if (to.query.redirectFrom) {
      next(vm => {
        vm.originalLocation = to.query.originalUrl;
        vm.errorMessage = errors.route.errorMessage;
        vm.errorDescription = errors.route.errorDescription;
      });
    } else {
      next();
    }
  }
};
</script>